import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { UserContext } from "../UserContext";
import { useAuth } from "../AuthContext";

export default function NewInventoryLocation({ closeNewLocation }) {
  const [name, setName] = useState("");
  const [userRef, setUserRef] = useContext(UserContext);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [missingInfo, setMissingInfo] = useState(false);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function addLocation(e) {
    e.preventDefault();

    setMissingInfo(false);

    if (name.length < 1) {
      setMissingInfo(true);
      return;
    } else {
      const newLocation = {
        name: name,
      };

      axios
        .post(`${serverAddress}/inventory/newLocation`, newLocation, headers)
        .then(() => {
          closeNewLocation(true);
        });
    }
  }

  return (
    <div>
      <div className="middleContainer-2">
        <div className="addOrgWrapper">
          <div>
            <h2>New Location</h2>
            <form onSubmit={addLocation}>
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {missingInfo && (
                <div className="red sectionSpacer">Must have a name.</div>
              )}
              <div className="spacerOne">
                <div>
                  <button type="submit" className="updateBtn">
                    Add
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => closeNewLocation(false)}
                    className="cancelUpdateBtn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
