import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import TopBar from "./TopBar";
import { useAuth } from "../AuthContext";
import Rooms from "./Rooms";
import TaskLists from "./TaskLists";
import { DashboardContext } from "../DashboardContext";
import { UserContext } from "../UserContext";
import PushSubscription from "./PushSubscription";
import Inventory from "./Inventory";
import Users from "./Users";
import Menu from "./Menu";

export default function Dashboard() {
  const currentUser = useAuth().currentUser;
  const [selectedScreen, setSelectedScreen] = useContext(DashboardContext);
  const [userRef, setUserRef] = useContext(UserContext);
  const [showMenu, setShowMenu] = useState(false);

  function updateShowMenu(value) {
    setShowMenu(value);
  }

  useEffect(() => {
    let unsubscribe;

    if (currentUser) {
      unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          setUserRef(doc.data());
        });
    } else {
      setUserRef();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  return (
    <>
      <TopBar showMenu={showMenu} updateShowMenu={updateShowMenu} />
      {userRef && (
        <>
          <div className={`menu ${showMenu ? "visible" : "hidden"}`}>
            <Menu updateShowMenu={updateShowMenu} />
          </div>
          <div className={`mainContent ${showMenu ? "blurred" : ""}`}>
            <div className="sectionSpacer">
              {selectedScreen === "" && (
                <div className="max700">
                  <div className="dashboardHeading">
                    Hello, {userRef.firstName}
                  </div>
                  <div className="sectionSpacer centeredText">
                    <div>
                      <button
                        type="button"
                        onClick={(e) => setSelectedScreen("rooms")}
                        className="cancelUpdateBtn"
                      >
                        Rooms
                      </button>
                    </div>
                    {userRef && userRef.canAccessInventory && (
                      <div className="margin-t-b-1">
                        <button
                          type="button"
                          onClick={(e) => setSelectedScreen("inventory")}
                          className="cancelUpdateBtn"
                        >
                          Inventory
                        </button>
                      </div>
                    )}
                    {userRef && userRef.canManageUsers && (
                      <div className="margin-t-b-1">
                        <button
                          type="button"
                          onClick={(e) => setSelectedScreen("users")}
                          className="cancelUpdateBtn"
                        >
                          Users
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {selectedScreen === "Rooms" && <Rooms userRef={userRef} />}
              {selectedScreen === "Inventory" &&
                userRef &&
                userRef.canAccessInventory && <Inventory />}
              {selectedScreen === "Users" &&
                userRef &&
                userRef.canManageUsers && <Users />}
            </div>
          </div>
        </>
      )}
    </>
  );
}
