import { useState } from "react";
import NewUser from "./NewUser";

export default function Users() {
  const [showNewUser, setShowNewUser] = useState(false);

  function closeNewUser() {
    setShowNewUser(false);
  }

  return (
    <div>
      {showNewUser ? (
        <NewUser closeNewUser={closeNewUser} />
      ) : (
        <div className="max700 centeredText">
          <h2 className="margin-t-b-2">User Management</h2>
          <div>
            <button onClick={() => setShowNewUser(true)} className="updateBtn">
              Create User
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
