import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import axios from "axios";

export default function NewUser({ closeNewUser }) {
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMisMatch, setPasswordMisMatch] = useState(false);
  const [createdUser, setCreatedUser] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  async function addUser() {
    if (error) {
      setError(false);
    }

    if (!headers) {
      return;
    }

    if (password === passwordConfirm) {
      const newUser = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        company: company,
        password: password,
      };

      try {
        await axios.post(`${serverAddress}/users/add`, newUser, headers);
        closeNewUser();
      } catch (e) {
        console.log("error creating user: ", e);
        setError(true);
      }
    } else {
      setPasswordMisMatch(true);
    }
  }

  return (
    <div>
      <div className="middleContainer-2">
        {createdUser ? (
          <div>created user</div>
        ) : (
          <div className="addOrgWrapper">
            <div>
              <h2>Add a User...</h2>
              <div>
                <input
                  type="text"
                  required
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="email"
                  required
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Company"
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="password"
                  required
                  placeholder="Confirm Password"
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </div>
              <div className="spacerOne">
                <div>
                  <button onClick={addUser} className="updateBtn">
                    Add
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={closeNewUser}
                    className="cancelUpdateBtn"
                  >
                    Cancel
                  </button>
                </div>
                {error && (
                  <div className="red margin-t-b-1">error creating user</div>
                )}
              </div>
              {passwordMisMatch && (
                <div className="red">Passwords don't match</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
