import { useEffect, useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import NewInventoryItem from "./NewInventoryItem";
import { useAuth } from "../AuthContext";
import NewInventoryLocation from "./NewInventoryLocation";

export default function Inventory() {
  const [locations, setLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [showNewItem, setShowNewItem] = useState(false);
  const [showNewLocation, setShowNewLocation] = useState(false);
  const [loading, setLoading] = useState(true);
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const [displayMode, setDisplayMode] = useState("items");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      if (displayMode === "items") {
        if (items.length === 0) {
          getItems();
        }
      } else {
        if (locations.length === 0) {
          getLocations();
        }
      }
    }
  }, [headers, displayMode]);

  function closeNewItem(needToReFetch) {
    setShowNewItem(false);
    if (needToReFetch) {
      getItems();
    }
  }

  function closeNewLocation(needToReFetch) {
    setShowNewLocation(false);
    if (needToReFetch) {
      getLocations();
      getItems();
    }
  }

  useEffect(() => {
    if (showDeleteButton && selectedItem === null) {
      setShowDeleteButton(false);
    }
  }, [selectedItem]);

  async function getLocations() {
    setLoading(true);
    try {
      const res = await axios.get(
        `${serverAddress}/inventory/getLocations`,
        headers
      );
      setLocations(res.data);
      setLoading(false);
    } catch (e) {
      console.log("error getting locations: ", e);
      setLoading(false);
    }
  }

  async function getItems() {
    try {
      const res = await axios.get(
        `${serverAddress}/inventory/getItems`,
        headers
      );
      for (const item of res.data) {
        item.totalQuantity = 0;
        for (const location of item.locations) {
          item.totalQuantity = item.totalQuantity + location.stockQuantity;
        }
      }
      setItems(res.data);
      setLoading(false);
    } catch (e) {
      console.log("error getting items: ", e);
      setLoading(false);
    }
  }

  function handlePlusClick() {
    if (displayMode === "items") {
      setShowNewItem(true);
    } else {
      setShowNewLocation(true);
    }
  }

  function handleQuantityChange(locationID, newQuantity) {
    setSelectedItem((prevState) => {
      // Update the stockQuantity for the specific location
      const updatedLocations = prevState.locations.map((loc) =>
        loc.locationID === locationID
          ? {
              ...loc,
              stockQuantity:
                newQuantity === ""
                  ? ""
                  : Math.max(0, parseInt(newQuantity, 10) || 0),
            }
          : loc
      );

      // Calculate the new totalQuantity, treating empty input as 0
      const newTotalQuantity = updatedLocations.reduce(
        (sum, loc) => sum + (parseInt(loc.stockQuantity, 10) || 0),
        0
      );

      // Return the updated selectedItem with updatedLocations and totalQuantity
      return {
        ...prevState,
        locations: updatedLocations,
        totalQuantity: newTotalQuantity,
      };
    });
  }

  async function saveUpdate() {
    try {
      for (const location of selectedItem.locations) {
        const updateData = {
          itemID: selectedItem.itemID,
          locationID: location.locationID,
          quantity: location.stockQuantity,
        };
        await axios.post(
          `${serverAddress}/inventory/updateStock`,
          updateData,
          headers
        );
      }
      setItems((prevState) =>
        prevState.map((item) =>
          item.itemID === selectedItem.itemID ? { ...selectedItem } : item
        )
      );
      setSelectedItem(null);
    } catch (e) {
      console.log("error updating stock: ", e);
    }
  }

  async function deleteItem() {
    try {
      await axios.post(
        `${serverAddress}/inventory/deleteItem`,
        { itemID: selectedItem.itemID },
        headers
      );
      setSelectedItem(null);
      getItems();
    } catch (e) {
      console.log("error deleting item: ", e);
    }
  }

  async function deleteLocation() {
    try {
      await axios.post(
        `${serverAddress}/inventory/deleteLocation`,
        { locationID: locationToDelete.id },
        headers
      );
      setLocationToDelete(null);
      getLocations();
      getItems();
    } catch (e) {
      console.log("error deleting location: ", e);
    }
  }

  return (
    <div className="max700">
      {showNewItem || showNewLocation ? (
        <>
          {showNewItem ? (
            <NewInventoryItem closeNewItem={closeNewItem} />
          ) : (
            <NewInventoryLocation closeNewLocation={closeNewLocation} />
          )}
        </>
      ) : (
        <>
          {selectedItem === null ? (
            <>
              <div className="spacedOutHeading">
                <div className="flex-align-row">
                  <h2 className="sectionSpacer">Inventory</h2>
                  <button onClick={handlePlusClick} className="iconBtn">
                    <FontAwesomeIcon icon={faPlus} className="margin-r-h" />
                  </button>
                </div>
                {!loading && (
                  <div>
                    <button
                      onClick={() => setDisplayMode("items")}
                      className={
                        displayMode === "items"
                          ? "borderBottomButton green"
                          : "borderBottomButton"
                      }
                    >
                      Items
                    </button>
                    <button
                      onClick={() => setDisplayMode("locations")}
                      className={
                        displayMode === "locations"
                          ? "borderBottomButton green"
                          : "borderBottomButton"
                      }
                    >
                      Locations
                    </button>
                  </div>
                )}
              </div>
              <PulseLoader
                color={"#ffffff"}
                loading={loading}
                size={20}
                className="loader"
              />
              {!loading && (
                <div className="sectionSpacer">
                  <div className="listLineTop"></div>
                  {displayMode === "items" ? (
                    <>
                      {items && items.length > 0 ? (
                        <div>
                          {items.map((item) => (
                            <div
                              onClick={() => setSelectedItem(item)}
                              className="pointer"
                              key={item.itemID}
                            >
                              <div className="flex-space-out">
                                <div>
                                  <div className="inventoryItemHeading">
                                    {item.partNumber}
                                  </div>
                                  <div>{item.description}</div>
                                </div>
                                <div>Qty: {item.totalQuantity}</div>
                              </div>
                              <div className="listLine"></div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>No Items</div>
                      )}
                    </>
                  ) : (
                    <>
                      {locations.length > 0 ? (
                        <div>
                          {locations.map((location) => (
                            <div key={location.id}>
                              <div className="flex-space-out">
                                <div>{location.name}</div>
                                {locationToDelete !== location ? (
                                  <div
                                    onClick={() =>
                                      setLocationToDelete(location)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="pointer"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex-btns">
                                    <div
                                      className="button redBtn"
                                      onClick={() => deleteLocation()}
                                    >
                                      delete
                                    </div>
                                    <div
                                      className="button whiteBtn"
                                      onClick={() => setLocationToDelete(null)}
                                    >
                                      cancel
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="listLine"></div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>No Locations</div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="max700">
              <div className="sectionSpacer">
                <div className="margin-t-b-h">Part Number:</div>
                <div className="inventoryItemHeading">
                  {selectedItem.partNumber}
                </div>
              </div>
              <div className="margin-t-b-h">
                <div className="margin-t-b-h">Description:</div>
                <div>{selectedItem.description}</div>
              </div>
              <div className="margin-t-b-1">
                <div className="margin-t-b-h">Total Quantity:</div>
                <div className="inventoryItemHeading">
                  {selectedItem.totalQuantity}
                </div>
              </div>
              <div className="margin-t-b-2">
                <div className="inventoryItemLocationsHeading margin-t-b-h">
                  Locations:
                </div>
                <div className="listLineTop"></div>
                {selectedItem.locations.map((location) => (
                  <div className="margin-t-b-1" key={location.locationID}>
                    <div className="flex-space-out">
                      <div>{location.locationName}</div>
                      <div className="flex-align-row">
                        <div className="margin-r-h">Qty:</div>
                        <input
                          type="number"
                          className="inventoryQuantityPicker"
                          min="0"
                          step="1"
                          pattern="[0-9]*"
                          placeholder="0"
                          value={location.stockQuantity}
                          onKeyDown={(e) => {
                            if (
                              ![
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Tab",
                              ].includes(e.key) &&
                              !/^[0-9]$/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleQuantityChange(location.locationID, value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="listLine"></div>
                  </div>
                ))}
              </div>
              <div className="centeredText">
                <button onClick={saveUpdate} className="updateBtn">
                  Save
                </button>
                <button
                  onClick={() => setSelectedItem(null)}
                  className="cancelUpdateBtn margin-t-b-1"
                >
                  Back
                </button>
                {!showDeleteButton ? (
                  <div className="margin-t-b-1">
                    <button
                      className="iconBtn"
                      onClick={(e) => setShowDeleteButton(true)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                ) : (
                  <>
                    <button onClick={deleteItem} className="redOutlineBtn">
                      Delete
                    </button>
                    <div
                      onClick={() => setShowDeleteButton(false)}
                      className="margin-t-b-1"
                    >
                      cancel
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
