import { useState, useEffect } from "react";

export default function TimePicker({
  hour,
  minute,
  period,
  onHourChange,
  onMinuteChange,
  onPeriodChange,
}) {
  return (
    <div className="flex-align-row">
      <select
        className="timePicker"
        value={hour}
        onChange={(e) => onHourChange(Number(e.target.value))}
      >
        {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <div>:</div>
      <select
        className="timePicker"
        value={minute}
        onChange={(e) => onMinuteChange(Number(e.target.value))}
      >
        {[0, 15, 30, 45, 59].map((minute) => (
          <option key={minute} value={minute}>
            {minute.toString().padStart(2, "0")}
          </option>
        ))}
      </select>
      <select
        className="timePicker"
        value={period}
        onChange={(e) => onPeriodChange(e.target.value)}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
}
