import { useEffect, useState } from "react";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  getDay,
  getMonth,
  getDate,
  isSameDay,
  endOfDay,
  getYear,
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function DatePicker({ onRangeChange }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [currentDay, setCurrentDay] = useState(getDate(new Date()));
  const [currentMonth, setCurrentMonth] = useState(getMonth(new Date()));
  const [currentYear, setCurrentYear] = useState(getYear(new Date()));
  const [selectedMonthDisplay, setSelectedMonthDisplay] = useState("");
  const dayLabels = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];
  const [days, setDays] = useState([]);
  const [startDayIndex, setStartDayIndex] = useState(
    getDay(startOfMonth(new Date(year, month, 1)))
  );

  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState();

  function handleDaySelection(selection) {
    const selectedDate = new Date(year, month, selection);
    if (!rangeStart) {
      setRangeStart(selectedDate);
    } else if (rangeStart && !rangeEnd) {
      if (rangeStart < selectedDate) {
        setRangeEnd(endOfDay(selectedDate));
      } else {
        setRangeEnd(endOfDay(rangeStart));
        setRangeStart(selectedDate);
      }
    } else {
      if (rangeStart < selectedDate) {
        setRangeEnd(endOfDay(selectedDate));
      } else {
        setRangeStart(selectedDate);
      }
    }
  }

  function clearRange() {
    setRangeStart();
    setRangeEnd();
  }

  useEffect(() => {
    if (onRangeChange) {
      onRangeChange(rangeStart, rangeEnd);
    }
  }, [rangeStart, rangeEnd]);

  function changeSelectedMonth(direction) {
    let newMonth = month + direction;
    if (newMonth < 0) {
      setMonth(11);
      setYear(year - 1);
    } else if (newMonth > 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(newMonth);
    }
  }

  useEffect(() => {
    setSelectedMonthDisplay(format(new Date(year, month), "MMMM yyyy"));
    let newDays = eachDayOfInterval({
      start: startOfMonth(new Date(year, month, 1)),
      end: endOfMonth(new Date(year, month, 1)),
    });
    setDays(newDays);
    setStartDayIndex(getDay(startOfMonth(new Date(year, month, 1))));
  }, [month]);

  return (
    <div className="datePickerContainer">
      <div className="datePickerMonthSelector">
        <FontAwesomeIcon
          className="calendarArrow"
          icon={faChevronLeft}
          onClick={() => changeSelectedMonth(-1)}
        />
        <div className="calendarHeading">{selectedMonthDisplay}</div>
        <FontAwesomeIcon
          className="calendarArrow"
          icon={faChevronRight}
          onClick={() => changeSelectedMonth(1)}
        />
      </div>
      <div className="calendarGrid">
        {dayLabels.map((day) => (
          <div key={day} className="calendarDayLabel">
            {day}
          </div>
        ))}

        {Array.from({ length: startDayIndex }).map((_, index) => (
          <div key={`empty-${index}`} style={{ padding: "8px" }}></div>
        ))}

        {days.map((day) => {
          const dayNumber = getDate(day);
          const isInRange =
            (rangeStart && !rangeEnd && isSameDay(day, rangeStart)) ||
            (rangeStart && rangeEnd && day >= rangeStart && day <= rangeEnd);
          return (
            <div
              key={day}
              className={`calendarGridCell ${
                dayNumber === currentDay &&
                currentMonth === month &&
                currentYear === year
                  ? "currentDayOnCalendar"
                  : ""
              } ${isInRange ? "calendarGridCellInRange" : ""}`}
              onClick={() => handleDaySelection(dayNumber)}
            >
              {format(day, "d")}
            </div>
          );
        })}
      </div>
      <div
        className={`centeredText margin-t-b-1 pointer ${
          !rangeStart && !rangeEnd ? "gray" : ""
        }`}
        onClick={clearRange}
      >
        clear
      </div>
    </div>
  );
}
