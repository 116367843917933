import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { UserContext } from "../UserContext";
import { useAuth } from "../AuthContext";

export default function NewInventoryItem({ closeNewItem }) {
  const [partNumber, setPartNumber] = useState("");
  const [description, setDescription] = useState("");
  const [userRef, setUserRef] = useContext(UserContext);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [missingInfo, setMissingInfo] = useState(false);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function addItem(e) {
    e.preventDefault();

    setMissingInfo(false);

    if (partNumber.length < 1 || description.length < 1) {
      setMissingInfo(true);
      return;
    } else {
      const newItem = {
        partNumber: partNumber,
        description: description,
      };

      axios
        .post(`${serverAddress}/inventory/newItem`, newItem, headers)
        .then(() => {
          closeNewItem(true);
        });
    }
  }

  return (
    <div>
      <div className="middleContainer-2">
        <div className="addOrgWrapper">
          <div>
            <h2>New Item</h2>
            <form onSubmit={addItem}>
              <div>
                <input
                  type="text"
                  placeholder="Part Number"
                  onChange={(e) => setPartNumber(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              {missingInfo && (
                <div className="red sectionSpacer">
                  Must have a part number and description.
                </div>
              )}
              <div className="spacerOne">
                <div>
                  <button type="submit" className="updateBtn">
                    Add
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => closeNewItem(false)}
                    className="cancelUpdateBtn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
