import { useState, useContext } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../AuthContext";
import LogoTop from "./LogoTop";
import { UserContext } from "../UserContext";
import { DashboardContext } from "../DashboardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faWindowClose } from "@fortawesome/free-solid-svg-icons";

export default function TopBar({ showMenu, updateShowMenu }) {
  const history = useHistory();
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const [userRef, setUserRef] = useContext(UserContext);
  const [selectedScreen, setSelectedScreen] = useContext(DashboardContext);

  async function logUserOut(e) {
    e.preventDefault();

    try {
      await logout();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className="topBarWrapper">
      <LogoTop />
      <div className="topBarBtn">
        {error && <p className="errorMsg">{error}</p>}
        {/* <button className="button whiteBtn" onClick={logUserOut}>
          Log Out
        </button> */}
        {showMenu ? (
          <button onClick={() => updateShowMenu(false)} className="menuButton">
            <FontAwesomeIcon icon={faWindowClose} />
          </button>
        ) : (
          <button onClick={() => updateShowMenu(true)} className="menuButton">
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
      </div>
    </div>
  );
}
