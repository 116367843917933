import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export default function PressureSensors({
  pressureDevices,
  updateSelectedForLogs,
}) {
  function displayAnalogData(
    id,
    type,
    enableLow,
    enableHigh,
    lowPoint,
    highPoint,
    value,
    name,
    loggingEnabled
  ) {
    let adjustment;
    if (type === "tank") {
      adjustment = 1;
    } else {
      adjustment = 5;
    }

    let valueClassName;
    let labelClassName;
    if (
      (enableLow && value <= lowPoint) ||
      (enableHigh && value >= highPoint)
    ) {
      valueClassName = "redSystemPSI";
      labelClassName = "redSystemPSIlabel";
    } else if (
      (enableLow && value <= lowPoint + adjustment) ||
      (enableHigh && value >= highPoint - adjustment)
    ) {
      valueClassName = "yellowSystemPSI";
      labelClassName = "yellowSystemPSIlabel";
    } else {
      valueClassName = "greenSystemPSI";
      labelClassName = "systemPSIlabel";
    }
    return (
      <div>
        <div className={valueClassName}>{value}</div>
        <div className={labelClassName}>{name}</div>
        <div className="levelsFlex">
          {enableLow && <div className="setPointLabel">L {lowPoint}</div>}
          {enableHigh && <div className="setPointLabel">H {highPoint}</div>}
        </div>
        {type === "pressure" && loggingEnabled && (
          <FontAwesomeIcon
            className="logsBtn"
            icon={faChartLine}
            onClick={() =>
              updateSelectedForLogs({ id: id, type: type, name: name })
            }
          />
        )}
      </div>
    );
  }

  return (
    <div className="psiContainer sectionSpacer">
      <div className="pumpRoomSection">
        <div className="pumpRoomHeading">
          <h2>PRESSURE (PSI)</h2>
        </div>
        <div className="sectionSpacer">
          <div className="statusFlexBox">
            {pressureDevices.map((device) => (
              <div className="systemPSIsectionWrapper" key={device.input_id}>
                <div>
                  {displayAnalogData(
                    device.input_id,
                    device.type,
                    device.enable_low_set_point,
                    device.enable_high_set_point,
                    device.low_set_point,
                    device.high_set_point,
                    device.current_value,
                    device.name,
                    device.logging_enabled
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
