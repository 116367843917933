import { useEffect, useState, useContext } from "react";
import { UserContext } from "../UserContext";
import { DashboardContext } from "../DashboardContext";
import { useAuth } from "../AuthContext";

export default function Menu({ updateShowMenu }) {
  const [userRef, setUserRef] = useContext(UserContext);
  const [selectedScreen, setSelectedScreen] = useContext(DashboardContext);
  const [menuItems, setMenuItems] = useState([]);
  const { logout } = useAuth();

  useEffect(() => {
    if (userRef) {
      if (userRef.canAccessInventory && userRef.canManageUsers) {
        setMenuItems(["Rooms", "Inventory", "Users", "Log Out"]);
      } else if (userRef.canAccessInventory) {
        setMenuItems(["Rooms", "Inventory", "Log Out"]);
      } else {
        setMenuItems(["Rooms", "Log Out"]);
      }
    }
  }, [userRef]);

  function selectOption(option) {
    if (option === "Log Out") {
      logUserOut();
    } else {
      setSelectedScreen(option);
      updateShowMenu(false);
    }
  }

  async function logUserOut() {
    // e.preventDefault();

    try {
      await logout();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className="menuContainer">
      <div className="menuHeading">Menu</div>
      <div className="margin-t-b-2">
        <div className="listLineTop"></div>
        {menuItems.map((item) => (
          <div onClick={() => selectOption(item)} key={item}>
            <div
              className={`pointer margin-t-b-1 ${
                selectedScreen === item ? "green" : ""
              }`}
            >
              {item}
            </div>
            <div className="listLine"></div>
          </div>
        ))}
      </div>
    </div>
  );
}
