import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDown,
  faCheck,
  faWindowClose,
  faPen,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";

export default function Contacts({ roomID }) {
  const [showAddContact, setShowAddContact] = useState(false);
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactEquipment, setContactEquipment] = useState("");
  const [selectedContact, setSelectedContact] = useState();
  const [updateContactFirstName, setUpdateContactFirstName] = useState("");
  const [updateContactLastName, setUpdateContactLastName] = useState("");
  const [updateContactEmail, setUpdateContactEmail] = useState("");
  const [updateContactPhone, setUpdateContactPhone] = useState("");
  const [updateContactCompany, setUpdateContactCompany] = useState("");
  const [updateContactEquipment, setUpdateContactEquipment] = useState("");
  const [contacts, setContacts] = useState([]);
  const [showContacts, setShowContacts] = useState(false);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [userRef, setUserRef] = useContext(UserContext);
  const [contToDelete, setContToDelete] = useState();

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      axios.get(`${serverAddress}/contacts/${roomID}`, headers).then((res) => {
        setContacts(res.data);
      });
    }
  }, [headers]);

  function deleteContact(c) {
    const contactID = c.id;
    const data = {
      id: contactID,
    };
    axios.post(`${serverAddress}/contacts/delete`, data, headers).then(() => {
      const modifiedContacts = contacts.filter((c) => c.id !== contactID);
      setContacts(modifiedContacts);
      setContToDelete();
    });
  }

  function addContact(e) {
    e.preventDefault();

    const contact = {
      firstName: contactFirstName,
      lastName: contactLastName,
      company: contactCompany,
      createdBy: userRef.uid,
      equipment: contactEquipment,
      email: contactEmail,
      phoneNumber: contactPhone,
      roomID: roomID,
    };

    axios.post(`${serverAddress}/contacts/add`, contact, headers).then(() => {
      axios.get(`${serverAddress}/contacts/${roomID}`, headers).then((res) => {
        setContacts(res.data);
        setContactFirstName("");
        setContactLastName("");
        setContactCompany("");
        setContactEquipment("");
        setContactEmail("");
        setContactPhone("");
        setShowAddContact(false);
      });
    });
  }

  function cancelAddContact(e) {
    e.preventDefault();

    setContactFirstName("");
    setContactLastName("");
    setContactCompany("");
    setContactEquipment("");
    setContactEmail("");
    setContactPhone("");
    setShowAddContact(false);
  }

  function updateContact(contactID) {
    const newInfo = {
      firstName: updateContactFirstName,
      lastName: updateContactLastName,
      company: updateContactCompany,
      equipment: updateContactEquipment,
      email: updateContactEmail,
      phone: updateContactPhone,
    };

    axios
      .post(`${serverAddress}/contacts/update/${contactID}`, newInfo, headers)
      .then(() => {
        axios
          .get(`${serverAddress}/contacts/${roomID}`, headers)
          .then((res) => {
            setContacts(res.data);
            setSelectedContact();
          });
      });
  }

  useEffect(() => {
    if (selectedContact) {
      setUpdateContactFirstName(selectedContact.first_name);
      setUpdateContactLastName(selectedContact.last_name);
      setUpdateContactCompany(selectedContact.company);
      setUpdateContactEquipment(selectedContact.equipment);
      setUpdateContactEmail(selectedContact.email);
      setUpdateContactPhone(selectedContact.phone_number);
    } else {
      setUpdateContactFirstName("");
      setUpdateContactLastName("");
      setUpdateContactCompany("");
      setUpdateContactEquipment("");
      setUpdateContactEmail("");
      setUpdateContactPhone("");
    }
  }, [selectedContact]);

  return (
    <div className="sectionSpacer">
      <div className="pumpRoomSection">
        {!showAddContact && (
          <div className="pumpRoomHeading">
            {showContacts === false && (
              <button
                onClick={(e) => setShowContacts(true)}
                className="arrowIconBtn"
              >
                <FontAwesomeIcon className="arrowIcon" icon={faAngleRight} />
              </button>
            )}
            {showContacts && (
              <button
                onClick={(e) => setShowContacts(false)}
                className="arrowIconBtn"
              >
                <FontAwesomeIcon className="arrowIcon" icon={faAngleDown} />
              </button>
            )}
            <h2>Emergency Contacts</h2>
            <button onClick={() => setShowAddContact(true)} className="iconBtn">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        )}
        {selectedContact === undefined && (
          <div>
            {showAddContact === false && (
              <div>
                {showContacts && (
                  <div className="sectionSpacer">
                    {contacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="serviceReportLinkWrapper"
                      >
                        <div className="flex-space-out">
                          <div className="contactName">
                            {contact.first_name} {contact.last_name}
                          </div>
                          <div className="flex-align-row">
                            {contToDelete === undefined && (
                              <button
                                value={contact.id}
                                onClick={(e) => setSelectedContact(contact)}
                                className="iconBtn"
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                            )}
                            {contToDelete !== contact ? (
                              <div onClick={() => setContToDelete(contact)}>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="iconBtn"
                                />
                              </div>
                            ) : (
                              <div className="flex-btns">
                                <div
                                  className="button redBtn"
                                  onClick={() => deleteContact(contToDelete)}
                                >
                                  delete
                                </div>
                                <div
                                  className="button whiteBtn"
                                  onClick={() => setContToDelete()}
                                >
                                  cancel
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="sectionSpacer">
                          <div className="contactInfo">Company:</div>
                          <div className="contactInfo">{contact.company}</div>
                        </div>
                        <div className="sectionSpacer">
                          <div className="contactInfo">Equipment:</div>
                          <div className="contactInfo">{contact.equipment}</div>
                        </div>
                        <div className="sectionSpacer">
                          <div className="contactInfo">Email:</div>
                          <div className="contactInfo">{contact.email}</div>
                        </div>
                        <div className="sectionSpacer">
                          <div className="contactInfo">Phone:</div>
                          <div className="contactInfo">
                            {contact.phone_number}
                          </div>
                        </div>
                        <div className="listLine"></div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {selectedContact && (
          <div className="addContact">
            <h2>Update Contact</h2>
            <input
              type="text"
              defaultValue={selectedContact.first_name}
              placeholder="First Name"
              onChange={(e) => setUpdateContactFirstName(e.target.value)}
            />
            <input
              type="text"
              defaultValue={selectedContact.last_name}
              placeholder="Last Name"
              onChange={(e) => setUpdateContactLastName(e.target.value)}
            />
            <input
              type="text"
              defaultValue={selectedContact.company}
              placeholder="Company"
              onChange={(e) => setUpdateContactCompany(e.target.value)}
            />
            <input
              type="text"
              defaultValue={selectedContact.equipment}
              placeholder="Equipment"
              onChange={(e) => setUpdateContactEquipment(e.target.value)}
            />
            <input
              type="email"
              defaultValue={selectedContact.email}
              placeholder="Email"
              onChange={(e) => setUpdateContactEmail(e.target.value)}
            />
            <input
              type="text"
              defaultValue={selectedContact.phone_number}
              placeholder="Phone Number"
              onChange={(e) => setUpdateContactPhone(e.target.value)}
            />
            <div className="addContBtnWrapper">
              <button onClick={(e) => setSelectedContact()} className="btnIcon">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button
                onClick={() => updateContact(selectedContact.id)}
                className="btnIcon"
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          </div>
        )}
        {showAddContact && (
          <div className="addContact">
            <h2>Add Contact</h2>
            <input
              type="text"
              placeholder="First Name"
              onChange={(e) => setContactFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name"
              onChange={(e) => setContactLastName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company"
              onChange={(e) => setContactCompany(e.target.value)}
            />
            <input
              type="text"
              placeholder="Equipment"
              onChange={(e) => setContactEquipment(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => setContactEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Phone Number"
              onChange={(e) => setContactPhone(e.target.value)}
            />
            <div className="addContBtnWrapper">
              <button onClick={cancelAddContact} className="btnIcon">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={addContact} className="btnIcon">
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
