import React, { useState } from "react";

export const DashboardContext = React.createContext();

export function DashboardProvider({ children }) {
  const [selectedScreen, setSelectedScreen] = useState("Rooms");

  return (
    <DashboardContext.Provider value={[selectedScreen, setSelectedScreen]}>
      {children}
    </DashboardContext.Provider>
  );
}
