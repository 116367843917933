import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { endOfDay } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import MotorRunLogs from "./MotorRunLogs";
import ChurnTests from "./ChurnTests";
import DatePicker from "./DatePicker";

export default function EventLog({ pumpRoomRef }) {
  const [serverEvents, setServerEvents] = useState([]);
  const [additionalEvents, setAdditionalEvents] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [lastEventTimestamp, setLastEventTimestamp] = useState();
  const [hideLoadMore, setHideLoadMore] = useState(true);
  const [showMotorRunLogs, setShowMotorRunLogs] = useState(false);
  const [showChurnTests, setShowChurnTests] = useState(false);
  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);

  const closeMotorRunReports = () => {
    setShowMotorRunLogs(false);
  };

  const closeChurnTests = () => {
    setShowChurnTests(false);
  };

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      loadInitialEvents();
    }
  }, [headers]);

  function loadInitialEvents() {
    setTimeout(() => {
      axios
        .get(`${serverAddress}/events/${pumpRoomRef.device}`, headers)
        .then((res) => {
          setServerEvents(res.data);
          setLoading(false);
          if (res.data.length > 0) {
            setHideLoadMore(false);
          }
        });
    }, [200]);
  }

  function getMoreEvents(e) {
    e.preventDefault();

    axios
      .get(
        `${serverAddress}/events/more/${pumpRoomRef.device}/${lastEventTimestamp}`,
        headers
      )
      .then((res) => setAdditionalEvents(res.data));
  }

  async function getFilteredEvents() {
    let endParam;
    if (rangeStart && !rangeEnd) {
      endParam = endOfDay(rangeStart);
      setRangeEnd(endParam);
    } else {
      endParam = rangeEnd;
    }
    try {
      const res = await axios.get(
        `${serverAddress}/events/filter/${pumpRoomRef.device}/${rangeStart}/${endParam}`,
        headers
      );
      setFilteredEvents(res.data);
      setShowFilter(false);
      setHideLoadMore(true);
    } catch (e) {
      console.log("error getting events: ", e);
    }
  }

  useEffect(() => {
    if (filteredEvents.length > 0) {
      setDisplayedEvents(filteredEvents);
    }
  }, [filteredEvents]);

  useEffect(() => {
    if (additionalEvents.length > 0) {
      setServerEvents([...serverEvents, ...additionalEvents]);
      if (additionalEvents.length < 10) {
        setShowMoreBtn(false);
      }
      setAdditionalEvents([]);
    }
  }, [additionalEvents]);

  useEffect(() => {
    if (serverEvents.length > 0) {
      setDisplayedEvents(serverEvents);
      setLastEventTimestamp(serverEvents[serverEvents.length - 1].timestamp);
      setFilteredEvents([]);
    }
  }, [serverEvents]);

  function runSearch() {
    if (!rangeStart) {
      console.log("no start date");
      return;
    }
    setSearchTerm("");
    getFilteredEvents();
  }

  function closeFilter() {
    setShowFilter(false);
    setRangeStart();
    setRangeEnd();
    loadInitialEvents();
  }

  function clearFilter() {
    setRangeStart();
    setRangeEnd();
    setSearchTerm("");
    if (!showFilter) {
      loadInitialEvents();
    }
  }

  function handleRangeChange(rangeStart, rangeEnd) {
    setRangeStart(rangeStart);
    setRangeEnd(rangeEnd);
  }

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    const convertedDate = date.toLocaleString("en-US", {
      timeZone: "America/New_York",
    });

    return convertedDate;
  }

  useEffect(() => {
    if (searchTerm.length > 0) {
      const eventsFilteredByTerm = filteredEvents.filter((event) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return event.message.toLowerCase().includes(lowerCaseSearchTerm);
      });
      setDisplayedEvents(eventsFilteredByTerm);
    } else {
      setDisplayedEvents(filteredEvents);
    }
  }, [searchTerm]);

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {pumpRoomRef.monitoring_enabled ? (
            <div>
              {!showMotorRunLogs && !showChurnTests && (
                <div>
                  <div className="eventLogHeading">
                    <div className="pumpRoomHeading2">
                      <h2>Event Log</h2>
                    </div>
                    {showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowFilter(true)}
                      >
                        filter
                      </div>
                    )}
                    {/* {showMotorRunLogs === false && showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowMotorRunLogs(true)}
                      >
                        motor run reports
                      </div>
                    )}
                    {showChurnTests === false && showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowChurnTests(true)}
                      >
                        churn tests
                      </div>
                    )} */}
                    {/* {showFilter && (
                      <div className="renameRoomBtn" onClick={closeFilter}>
                        close
                      </div>
                    )} */}
                    {rangeStart && rangeEnd && (
                      <div className="renameRoomBtn2" onClick={clearFilter}>
                        reset
                      </div>
                    )}
                  </div>
                  <div className="sectionSpacer">
                    {!showFilter && (
                      <div>
                        <div>
                          <div className="eventWrapper">
                            <div className="listLineTopWide"></div>
                            {rangeStart && rangeEnd && (
                              <input
                                type="text"
                                placeholder="search term"
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            )}
                          </div>
                          <div className="sectionSpacer">
                            {displayedEvents.length > 0 ? (
                              <div>
                                {displayedEvents.map((event) => (
                                  <div className="eventWrapper" key={event.id}>
                                    <div>Device: {event.input_name}</div>
                                    {event.alert_status ? (
                                      <div>
                                        {event.input_type === "pump" ? (
                                          <div>
                                            <div className="greenEvent eventMessage">
                                              {event.message}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="redEvent eventMessage">
                                            {event.message}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="eventMessage">
                                        {event.message}
                                      </div>
                                    )}
                                    <div>
                                      {convertTimestamp(event.timestamp)}
                                    </div>
                                    <div className="listLine2"></div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="centeredText">
                                No event history
                              </div>
                            )}
                          </div>
                          {!hideLoadMore && (
                            <div className="centeredText">
                              {showMoreBtn ? (
                                <button
                                  className="loadMoreBtn"
                                  onClick={getMoreEvents}
                                >
                                  Load More
                                </button>
                              ) : (
                                <div>NO MORE EVENTS</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {showFilter && (
                      <>
                        <DatePicker onRangeChange={handleRangeChange} />
                        <>
                          {!loading && (
                            <div
                              className="logsFilterBottomButton"
                              onClick={runSearch}
                            >
                              Search
                            </div>
                          )}
                          <div
                            className="logsFilterBottomButton"
                            onClick={closeFilter}
                          >
                            Close
                          </div>
                        </>
                      </>
                    )}
                  </div>
                </div>
              )}
              {showMotorRunLogs && (
                <MotorRunLogs
                  closeMotorRunReports={closeMotorRunReports}
                  deviceID={pumpRoomRef.device}
                />
              )}
              {showChurnTests && (
                <ChurnTests
                  closeChurnTests={closeChurnTests}
                  deviceID={pumpRoomRef.device}
                />
              )}
            </div>
          ) : (
            <div className="middleContainer">
              NO ACTIVE REMOTE MONITORING PLAN
            </div>
          )}
        </div>
      )}
    </div>
  );
}
