import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faSignal,
  faImage,
  faHammer,
  faFolder,
  faInfoCircle,
  faChevronLeft,
  faPen,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import TopBar from "./TopBar";
import RoomInfo from "./RoomInfo";
import Documents from "./Documents";
import Images from "./Images";
import Status from "./Status";
import EventLog from "./EventLog";
import RoomUsers from "./RoomUsers";
import RoomTasks from "./RoomTasks";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";
import Contacts from "./Contacts";
import Monitoring from "./Monitoring";

export default function PumpRoom({ roomID, backToRooms }) {
  const [userRef, setUserRef] = useContext(UserContext);
  const [pumpRoomRef, setPumpRoomRef] = useState();
  const [showUpdateRoom, setShowUpdateRoom] = useState(false);
  const [addressInput, setAddressInput] = useState();
  const [nameInput, setNameInput] = useState();
  const [cityInput, setCityInput] = useState();
  const [stateInput, setStateInput] = useState();
  const [companyInput, setCompanyInput] = useState();
  const [currentPage, setCurrentPage] = useState("info");
  const [infoTabStyle, setInfoTabStyle] = useState("selectedTabIcon");
  const [docTabStyle, setDocTabStyle] = useState("tabIcon");
  const [statusTabStyle, setStatusTabStyle] = useState("tabIcon");
  const [imgTabStyle, setImgTabStyle] = useState("tabIcon");
  const [taskTabStyle, setTaskTabStyle] = useState("tabIcon");
  const [eventLogTabStyle, setEventLogTabStyle] = useState("tabIcon");
  const [showUsers, setShowUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDeleteRoom, setShowDeleteRoom] = useState(false);
  const [missingNameOrAddress, setMissingNameOrAddress] = useState(false);

  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      if (headers) {
        axios
          .get(`${serverAddress}/rooms/ref/${roomID}`, headers)
          .then((res) => setPumpRoomRef(res.data));
      }
    }, 200);
  }, [headers]);

  useEffect(() => {
    if (pumpRoomRef) {
      setLoading(false);
    }
  }, [pumpRoomRef]);

  useEffect(() => {
    if (currentPage === "info") {
      setInfoTabStyle("selectedTabIcon");
    } else {
      setInfoTabStyle("tabIcon");
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "documents") {
      setDocTabStyle("selectedTabIcon");
    } else {
      setDocTabStyle("tabIcon");
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "images") {
      setImgTabStyle("selectedTabIcon");
    } else {
      setImgTabStyle("tabIcon");
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "tasks") {
      setTaskTabStyle("selectedTabIcon");
    } else {
      setTaskTabStyle("tabIcon");
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "status") {
      setStatusTabStyle("selectedTabIcon");
    } else {
      setStatusTabStyle("tabIcon");
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "eventLog") {
      setEventLogTabStyle("selectedTabIcon");
    } else {
      setEventLogTabStyle("tabIcon");
    }
  }, [currentPage]);

  function updateRoom(e) {
    e.preventDefault();

    setMissingNameOrAddress(false);

    if (nameInput.length < 1 && addressInput.length < 1) {
      setMissingNameOrAddress(true);
      return;
    } else {
      const newInfo = {
        address: addressInput,
        name: nameInput,
        city: cityInput,
        state: stateInput,
        company: companyInput,
      };

      axios
        .post(`${serverAddress}/rooms/update/${roomID}`, newInfo, headers)
        .then(() => {
          pumpRoomRef.address = addressInput;
          pumpRoomRef.name = nameInput;
          pumpRoomRef.city = cityInput;
          pumpRoomRef.state = stateInput;
          pumpRoomRef.company = companyInput;
          clearInput();
          setShowUpdateRoom(false);
        });
    }
  }

  function cancelUpdate(e) {
    e.preventDefault();

    clearInput();
    setShowUpdateRoom(false);
  }

  useEffect(() => {
    if (showUpdateRoom) {
      setAddressInput(pumpRoomRef.address);
      setNameInput(pumpRoomRef.name);
      setCityInput(pumpRoomRef.city);
      setStateInput(pumpRoomRef.state);
      setCompanyInput(pumpRoomRef.company);
    } else {
      clearInput();
    }
  }, [showUpdateRoom]);

  function clearInput() {
    setAddressInput();
    setNameInput();
    setCityInput();
    setStateInput();
    setCompanyInput();
  }

  function deleteRoom() {
    const data = {
      id: roomID,
    };
    axios.post(`${serverAddress}/rooms/deleteRoom/`, data, headers).then(() => {
      backToRooms();
    });
  }

  return (
    <>
      <div className="pumpRoomWrapper">
        <PulseLoader
          color={"#ffffff"}
          loading={loading}
          size={20}
          className="loader"
        />
        {pumpRoomRef && (
          <div>
            {showUsers === false && (
              <div>
                {showUpdateRoom === false && (
                  <div className="w-95p">
                    <div className="pathDisplay">
                      <button className="backBtn">
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          onClick={backToRooms}
                        />
                      </button>
                      {pumpRoomRef.name.length > 0 ? (
                        <h2>{pumpRoomRef.name}</h2>
                      ) : (
                        <h2>{pumpRoomRef.address}</h2>
                      )}
                      {userRef.canCreateRooms && (
                        <div className="flex-align-row">
                          <button
                            className="iconBtn"
                            onClick={(e) => setShowUpdateRoom(true)}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                          {!showDeleteRoom ? (
                            <button
                              className="iconBtn"
                              onClick={(e) => setShowDeleteRoom(true)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          ) : (
                            <div className="flex-btns">
                              <div
                                className="button whiteBtn"
                                onClick={() => setShowDeleteRoom(false)}
                              >
                                cancel
                              </div>
                              <button
                                className="button redBtn"
                                onClick={deleteRoom}
                              >
                                delete
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {userRef.canManageUsers && (
                        <button
                          className="iconBtn"
                          onClick={(e) => setShowUsers(true)}
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                      )}
                    </div>
                    <div className="roomTabs">
                      <div className="roomTab">
                        <FontAwesomeIcon
                          className={infoTabStyle}
                          icon={faInfoCircle}
                          onClick={(e) => setCurrentPage("info")}
                        />
                      </div>
                      <div className="roomTab">
                        <FontAwesomeIcon
                          className={docTabStyle}
                          icon={faFolder}
                          onClick={(e) => setCurrentPage("documents")}
                        />
                      </div>
                      <div className="roomTab">
                        <FontAwesomeIcon
                          className={imgTabStyle}
                          icon={faImage}
                          onClick={(e) => setCurrentPage("images")}
                        />
                      </div>
                      {/* <div className="roomTab">
                        <FontAwesomeIcon
                          className={taskTabStyle}
                          icon={faHammer}
                          onClick={(e) => setCurrentPage("tasks")}
                        />
                      </div> */}
                      <div
                        onClick={(e) => setCurrentPage("status")}
                        className="roomTab"
                      >
                        <FontAwesomeIcon
                          className={statusTabStyle}
                          icon={faSignal}
                          onClick={(e) => setCurrentPage("status")}
                        />
                      </div>
                      <div
                        onClick={(e) => setCurrentPage("eventLog")}
                        className="roomTab"
                      >
                        <FontAwesomeIcon
                          className={eventLogTabStyle}
                          icon={faBook}
                          onClick={(e) => setCurrentPage("eventLog")}
                        />
                      </div>
                    </div>
                    {currentPage === "info" && (
                      <div>
                        <RoomInfo roomID={roomID} pumpRoomRef={pumpRoomRef} />
                        <Contacts roomID={roomID} />
                      </div>
                    )}
                    {/* {currentPage === "tasks" && (
                      <div>
                        <RoomTasks roomID={roomID} />
                      </div>
                    )} */}
                    {currentPage === "status" && (
                      <div>
                        <div>
                          <div>
                            {pumpRoomRef.device === "RM1000" ? (
                              <Status pumpRoomRef={pumpRoomRef} />
                            ) : (
                              <Monitoring pumpRoomRef={pumpRoomRef} />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {currentPage === "eventLog" && (
                      <div>
                        <div>
                          <EventLog pumpRoomRef={pumpRoomRef} />
                        </div>
                      </div>
                    )}
                    {currentPage === "images" && (
                      <div>
                        <Images roomID={roomID} />
                      </div>
                    )}
                    {currentPage === "documents" && (
                      <div>
                        <Documents roomID={roomID} roomRef={pumpRoomRef} />
                      </div>
                    )}
                  </div>
                )}
                {showUpdateRoom && (
                  <div className="middleContainer-2">
                    <div className="addOrgWrapper">
                      <div>
                        <h2>Update Room Info...</h2>
                        <form onSubmit={updateRoom}>
                          <div>
                            <input
                              type="text"
                              placeholder="Address"
                              defaultValue={addressInput}
                              onChange={(e) => setAddressInput(e.target.value)}
                            />
                            <input
                              type="text"
                              required
                              placeholder="City"
                              defaultValue={cityInput}
                              onChange={(e) => setCityInput(e.target.value)}
                            />
                            <input
                              type="text"
                              required
                              placeholder="State"
                              defaultValue={stateInput}
                              onChange={(e) => setStateInput(e.target.value)}
                            />
                            <input
                              type="text"
                              required
                              placeholder="Company"
                              defaultValue={companyInput}
                              onChange={(e) => setCompanyInput(e.target.value)}
                            />
                            <input
                              type="text"
                              placeholder="Room Name"
                              defaultValue={nameInput}
                              onChange={(e) => setNameInput(e.target.value)}
                            />
                          </div>
                          {missingNameOrAddress && (
                            <div className="red sectionSpacer">
                              Must have either an address or room name.
                            </div>
                          )}
                          <div className="spacerOne">
                            <div>
                              <button type="submit" className="updateBtn">
                                Update
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={cancelUpdate}
                                className="cancelUpdateBtn"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {showUsers && (
              <div>
                <RoomUsers roomID={roomID} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
